<template>
    <div>
        Booking
    </div>
</template>

<script>
export default {
    
}
</script>